;(function (d) {
  'use strict'
  const yt = d.querySelectorAll('figure.vid');
  const vbttn = d.querySelectorAll('button.vbttn');
  const forEach = [].forEach;

  let ready = fn => {
    if (d.attachEvent ? d.readyState === 'complete' : d.readyState !== 'loading') {
      fn()
    } else {
      d.addEventListener('DOMContentLoaded', fn)
    }
  }

  let ytLoad = () => {
    forEach.call(yt, el => {
      const button = el.firstChild
      let aratio = el.dataset.ratio || undefined;
      let isYT = el.classList.contains('youtube') === true;
      let isVimeo = el.classList.contains('vimeo') === true;
      let fsrc;
      if (isYT) {
        const source = 'https://img.youtube.com/vi/' + el.dataset.embed + '/hqdefault.jpg';
        el.style.backgroundImage = 'linear-gradient(rgba(0, 0, 0, .6) 0, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%), url(' + source + ')'
      }
      button.addEventListener('click', function () {
        if (isYT) {
          fsrc = 'https://www.youtube.com/embed/' + el.dataset.embed + '?rel=0&showinfo=0&autoplay=1';
        } else if (isVimeo) {
          fsrc = 'https://player.vimeo.com/video/' + el.dataset.embed + '?autoplay=1';
        }
        let iframe = getIframe(fsrc);
        el.style.backgroundImage = 'none'
        el.innerHTML = ''
        el.appendChild(iframe)
      })
      if (aratio) {
        el.style.paddingTop = aspect(aratio);
      }
    })
  }

  let aspect = ar => {
    let i = ar.split(":");
    return ((i[1] / i[0]) * 100) + "%";
  }

  let vbttnLoad = () => {
    forEach.call(vbttn, el => {
      let code = el.dataset.vidCode;
      let wrap = d.getElementById(el.dataset.vidWrapper);
      let tain = wrap.parentNode;
      el.addEventListener('click', function () {
        let fsrc = 'https://www.youtube.com/embed/' + code + '?rel=0&showinfo=0&autoplay=1';
        let iframe = getIframe(fsrc);
        wrap.classList.add('vid');
        wrap.innerHTML = ''
        wrap.appendChild(iframe)
        tain.classList.add('blck');
      });
    });
  }

  let getIframe = fsrc => {
    let ifrm = d.createElement('iframe')
    ifrm.setAttribute('frameborder', '0')
    ifrm.setAttribute('allowfullscreen', '')
    ifrm.setAttribute('src', fsrc)
    return ifrm;
  }

  if (yt) {
    ready(ytLoad)
  }
  if (vbttn) {
    ready(vbttnLoad)
  }
})(document)
